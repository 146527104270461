import React from "react";
import { callMapper, resolveAppendableClasses } from "../../../../util/util";
import ColouredProgressBar from "../../../sharedComponents/ColouredProgressBar/ColouredProgressBar";
import GenericCard from "../../GenericCard/GenericCard";
import { OrderStatuses } from "../../../../enums";
import ClipBoardIcon from "../../../../assets/icons/ClipBoardIcon";
import FollowLinkIcon from "../../../../assets/icons/FollowLinkIcon";
import { courierTrackUrl } from "../../../../config.json";

function PrescriptionCardForSidePreview({ className = "", appendableClassNames = "", prescription }) {
  const renderCircle = () => {
    return <div className={`prescription_card_for_side_preview__circle ${resolveAppendableClasses("prescription_card_for_side_preview__circle", callMapper(prescriptionStatusToStatusCircleMapper, prescription?.status, "orange"))}`} />
  }
  function updateClipboard(text) {
    navigator.clipboard.writeText(text).then(function () {
      /* clipboard successfully set */
    }, function () {
      /* clipboard write failed */
    });
  }

  function handleOpenTrackingLink() {
    window.open(`${courierTrackUrl}`, "_blank");
  }

  const renderTrackingNumber = () => {
    if (!prescription?.externalTrackingCode) {
      return <></>
    }
    return <>
      <div className={"prescription_card_for_side_preview__section_title m-t-s m-b-xs"}>
        Tracking number
      </div>
      <div className={"prescription_card_for_side_preview__large_text prescription_card_for_side_preview__large_text--link "}>
        <div onClick={() => { handleOpenTrackingLink() }} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {prescription?.externalTrackingCode} &nbsp; <FollowLinkIcon />
        </div>
        <span style={{ cursor: "pointer" }} onClick={() => { updateClipboard(prescription?.externalTrackingCode) }}  >
          <ClipBoardIcon />
        </span>
      </div>
    </>

  }

  const renderProducts = () => {
    if (!prescription?.products?.length || prescription?.status === 0 || prescription?.status === 1) {
      return <></>
    }
    return <>
      <div className={"prescription_card_for_side_preview__section_title m-t-s m-b-xs"}>
        Products
      </div>
      {
        prescription?.products?.map((product, i) => {
          return <div key={i} className={`prescription_card_for_side_preview__product`} >
            • {product.name} x {product.amount}
          </div>
        })
      }
    </>
  }
  return (
    <div
      className={` prescription_card_for_side_preview ${className} ${resolveAppendableClasses("prescription_card_for_side_preview", appendableClassNames)}`}
    >
      <GenericCard className={"m-t-s"} appendableClassNames={`orange ${callMapper(classNameForCardMapper, prescription?.status, "gray")} `} >
        <ColouredProgressBar appendableClassNames={`${callMapper(prescriptionStatusToProgressBarClassnameMapper, prescription?.status, "")}`} />
        {
          prescription?.orderId && prescription?.status !== 0 && prescription?.status !== 1 &&
          <>
            <div className={"prescription_card_for_side_preview__section_title m-t-s m-b-xs"}>
              Order number
            </div>
            <div className={"prescription_card_for_side_preview__large_text"}>
              {prescription?.orderId || "N/A"}
            </div>
          </>
        }

        <div className={"prescription_card_for_side_preview__section_title m-t-s m-b-xs"}>
          Status
        </div>
        <div className={"prescription_card_for_side_preview__status"}>
          {renderCircle()} <div className={"m-l-s"} >{callMapper(contentForStatusTextMapper, prescription?.status, 'No status')}</div>
        </div>

        {renderTrackingNumber()}
        {renderProducts()}

      </GenericCard>
    </div>
  );
}

export default PrescriptionCardForSidePreview;

const contentForStatusTextMapper = {
  [OrderStatuses.Archived]: "Archived",
  [OrderStatuses.Complete]: "Order delivered to patient",
  [OrderStatuses.Cancelled]: "Cancelled",
  [OrderStatuses.NotApproved]: "Lyphe Dispensary is currently processing this prescription",
  [OrderStatuses.NoPaperAndPaymentMissed]: "7 day payment window has expired",
  [OrderStatuses.PaperAndPaymentMissed]: "7 day payment window has expired",
  [OrderStatuses.NotPaidAndPaper]: "Waiting on patient payment",
  [OrderStatuses.NotPaidAndNoPaper]: "Waiting on patient payment",
  [OrderStatuses.PaidAndPaper]: "Pharmacy team is getting the order ready",
  [OrderStatuses.PaidAndNoPaper]: "Waiting on paper copy to arrive",
  [OrderStatuses.CompletedAndVoid]: "Prescription expired",
  [OrderStatuses.OutOfStockAndPaper]: "Lyphe Dispensary is currently processing this prescription",
  [OrderStatuses.OutOfStock]: "Lyphe Dispensary is currently processing this prescription",
  [OrderStatuses.Void]: "Prescription expired",
  [OrderStatuses.Shipped]: "Order has been shipped",
}

const classNameForCardMapper = {
  [OrderStatuses.Archived]: "gray",
  [OrderStatuses.Complete]: "green",
  [OrderStatuses.Cancelled]: "red",
  [OrderStatuses.NotApproved]: "gray",
  [OrderStatuses.NoPaperAndPaymentMissed]: "gray",
  [OrderStatuses.PaperAndPaymentMissed]: "gray",
  [OrderStatuses.NotPaidAndPaper]: "gray",
  [OrderStatuses.NotPaidAndNoPaper]: "gray",
  [OrderStatuses.PaidAndPaper]: "gray",
  [OrderStatuses.PaidAndNoPaper]: "gray",
  [OrderStatuses.CompletedAndVoid]: "red",
  [OrderStatuses.OutOfStockAndPaper]: "gray",
  [OrderStatuses.OutOfStock]: "gray",
  [OrderStatuses.Void]: "red",
  [OrderStatuses.Shipped]: "gray",
}

const prescriptionStatusToProgressBarClassnameMapper = {
  [OrderStatuses.Archived]: "orange 100",
  [OrderStatuses.Complete]: "green 100",
  [OrderStatuses.Cancelled]: "red 100",
  [OrderStatuses.NotApproved]: "",
  [OrderStatuses.NoPaperAndPaymentMissed]: "red 100",
  [OrderStatuses.PaperAndPaymentMissed]: "red 100",
  [OrderStatuses.NotPaidAndPaper]: "blue 20",
  [OrderStatuses.NotPaidAndNoPaper]: "blue 20",
  [OrderStatuses.PaidAndPaper]: "blue 70",
  [OrderStatuses.PaidAndNoPaper]: "blue 40",
  [OrderStatuses.CompletedAndVoid]: "red 100",
  [OrderStatuses.OutOfStockAndPaper]: "",
  [OrderStatuses.OutOfStock]: "",
  [OrderStatuses.Void]: "red 100",
  [OrderStatuses.Shipped]: "blue 90",
}

const prescriptionStatusToStatusCircleMapper = {
  [OrderStatuses.Archived]: "orange",
  [OrderStatuses.Complete]: "green",
  [OrderStatuses.Cancelled]: "red",
  [OrderStatuses.NotApproved]: "gray",
  [OrderStatuses.NoPaperAndPaymentMissed]: "red",
  [OrderStatuses.PaperAndPaymentMissed]: "red",
  [OrderStatuses.NotPaidAndPaper]: "blue",
  [OrderStatuses.NotPaidAndNoPaper]: "blue",
  [OrderStatuses.PaidAndPaper]: "blue",
  [OrderStatuses.PaidAndNoPaper]: "blue",
  [OrderStatuses.CompletedAndVoid]: "red",
  [OrderStatuses.OutOfStockAndPaper]: "gray",
  [OrderStatuses.OutOfStock]: "gray",
  [OrderStatuses.Void]: "red",
  [OrderStatuses.Shipped]: "blue",
}