import React, { useState } from "react";
import { useSelector } from "react-redux";

import DesktopOrderHeader from "./DesktopOrderHeader/DesktopOrderHeader";
import MobileOrderHeader from "./MobileOrderHeader/MobileOrderHeader";
import {
  DownloadInvoiceButton,
  PayButton,
  ResubmitButton,
  TrackingButton,
  ViewPrescriptionButton,
} from "../../../buttons";

import { OrderStatuses, UserRoles } from "../../../../enums";
import { getPluralText } from "../../../../util/util";
import { selectUser } from "../../../../store/slices/authenticationSlice";
import VerticalSeparator from "../../../VerticalSeparator/VerticalSeparator";
import {
  selectActivePrescriptions,
  selectTrackedOrderIndex,
} from "../../../../store/slices/prescriptionsSlice";
import {
  OrderStatusDispatchedIcon,
  OrderStatusMedicationReadyIcon,
  OrderStatusOrderWithManufacturerIcon,
  OrderStatusPaperNeededIcon,
  OrderStatusPaymentDeadlineMissedIcon,
  OrderStatusPrescriptionExpiredIcon,
} from "../../../../assets/icons";
import OrderStatusPaymentNeededIcon from "../../../../assets/icons/OrderStatusPaymentNeededIcon";
import ViewPerscriptionPopupDoctor from "../../../popups/ViewPerscriptionPopupDoctor/ViewPerscriptionPopupDoctor";
function OrderHeader({ order, allOrders }) {

  const user = useSelector(selectUser);
  const trackedOrderIndex = useSelector(selectTrackedOrderIndex);
  const activePrescriptions = useSelector(selectActivePrescriptions);
  const [showViewPrescriptionDoctorPopup, setShowViewPrescriptionDoctorPopup] = useState(false)

  const handleShowHideViewPrescriptionDoctorPopup = () => {
    setShowViewPrescriptionDoctorPopup(!showViewPrescriptionDoctorPopup)
  }

  const buttons = {
    left: [],
    right: [
      user?.role !== 3 ? <ViewPrescriptionButton key={`view${order.index}`} order={order} /> : <ViewPerscriptionPopupDoctor key={`view${order.index}`} close={handleShowHideViewPrescriptionDoctorPopup} show={showViewPrescriptionDoctorPopup} data={order} />,
    ],
  };


  if (activePrescriptions.length > 1 || user.role !== UserRoles.OrganisationEmployee) {
    buttons.left.push(
      <TrackingButton key={`tracking${order.index}`} order={order} />
    );
  }

  // get buttons and text for the header
  getButtonsMapper[order.status](buttons, order, user?.role);

  const orderHeaderContent = user?.role === 3 ? OrderHeaderToTextMapperForDoctor[order.status] : OrderHeaderToTextMapper[order.status];
  const numberOfProducts = order.products?.length;
  const numberOfProductsText = getPluralText("ITEM", numberOfProducts);

  const productDetails =
    user?.role !== 3
      ? [
        { title: `${numberOfProductsText} IN THIS ORDER` },
        { title: "PRESCRIPTION NO: ", text: order.paperPrescriptionId },
        { title: "PRESCRIBED BY: ", text: order.prescribedBy },
        { title: "PRESCRIPTION UPLOADED: ", text: order.prescriptionUploadDate },
      ]
      : [
        {
          title: "PATIENT NAME: ",
          text: `${order.patient.firstName} ${order.patient.lastName}`,
        },
        { title: "PRESCRIPTION NO: ", text: order.paperPrescriptionId },
        { title: "PRESCRIPTION UPLOADED: ", text: order.prescriptionUploadDate },
      ];

  const isOrderTracked = order.index === trackedOrderIndex;

  const ordersForThisPrescriptionId = allOrders.filter((_order) => _order.prescriptionId === order.prescriptionId);
  const splitOrders = ordersForThisPrescriptionId.filter((_order) => _order.isSplit);
  const numberOfSplitOrders = splitOrders.length;
  const indexOfSplitOrder = splitOrders.findIndex(_order => _order.orderId === order.orderId) + 1;

  return (
    <>
      <DesktopOrderHeader
        order={order}
        buttons={buttons}
        content={orderHeaderContent}
        OrderStatusIcon={OrderHeaderToIconsMapperPatientCarer[order.status]}
        productDetails={productDetails}
        isOrderTracked={isOrderTracked}
        numberOfSplitOrders={numberOfSplitOrders || 0}
        indexOfSplitOrder={indexOfSplitOrder}
      />
      <MobileOrderHeader
        buttons={buttons}
        numberOfProductsText={getPluralText("ITEM", order.products.length)}
        content={orderHeaderContent}
        OrderStatusIcon={OrderHeaderToIconsMapperPatientCarer[order.status]}
        productDetails={productDetails}
        isOrderTracked={isOrderTracked}
        order={order}
        numberOfSplitOrders={numberOfSplitOrders || 0}
        indexOfSplitOrder={indexOfSplitOrder}
      />
    </>
  );
}

export default OrderHeader;

const getButtonsMapper = {
  [OrderStatuses.OutOfStock]: () => null,
  [OrderStatuses.NotPaidAndNoPaper]: addPayButton,
  [OrderStatuses.NotPaidAndPaper]: addPayButton,
  [OrderStatuses.PaidAndNoPaper]: addDownloadInvoiceButton,
  [OrderStatuses.PaidAndPaper]: addDownloadInvoiceButton,
  [OrderStatuses.Shipped]: addDownloadInvoiceButton,
  [OrderStatuses.NoPaperAndPaymentMissed]: addResubmitButton,
  [OrderStatuses.PaperAndPaymentMissed]: addResubmitButton,
  [OrderStatuses.OutOfStockAndPaper]: () => null,
  [OrderStatuses.Complete]: () => null,
  [OrderStatuses.CompletedAndVoid]: () => null,
  [OrderStatuses.Cancelled]: () => null,
  [OrderStatuses.Void]: () => null,
  [OrderStatuses.Archived]: () => null,
  undefined: () => null,
};

function addPayButton(buttons, order, userRole) {
  // don't show for doctors
  if (userRole === 3) return;

  buttons.left.push(<PayButton key={`pay${order.index}`} order={order} />);
}

function addDownloadInvoiceButton(buttons, order, userRole) {
  // don't show for doctors
  if (userRole === 3) return;

  buttons.right.push(<VerticalSeparator key={`separator${order.id}`} />);

  buttons.right.push(
    <DownloadInvoiceButton key={`download${order.index}`} order={order} />
  );
}

function addResubmitButton(buttons, order, userRole) {
  // don't show for doctors
  if (userRole === 3) return;

  buttons.left.push(
    <ResubmitButton key={`resubmit${order.index}`} order={order} />
  );
}

// Icons for OrderHeader based on order status
const OrderHeaderToIconsMapperPatientCarer = {
  [OrderStatuses.OutOfStock]: OrderStatusOrderWithManufacturerIcon,
  [OrderStatuses.OutOfStockAndPaper]: OrderStatusOrderWithManufacturerIcon,
  [OrderStatuses.NotPaidAndNoPaper]: OrderStatusPaymentNeededIcon,
  [OrderStatuses.NotPaidAndPaper]: OrderStatusPaymentNeededIcon,
  [OrderStatuses.PaidAndNoPaper]: OrderStatusPaperNeededIcon,
  [OrderStatuses.PaidAndPaper]: OrderStatusMedicationReadyIcon,
  [OrderStatuses.Shipped]: OrderStatusDispatchedIcon,
  [OrderStatuses.PaperAndPaymentMissed]: OrderStatusPaymentDeadlineMissedIcon,
  [OrderStatuses.NoPaperAndPaymentMissed]: OrderStatusPaymentDeadlineMissedIcon,
  [OrderStatuses.Void]: OrderStatusPrescriptionExpiredIcon,
  [OrderStatuses.CompletedAndVoid]: OrderStatusPrescriptionExpiredIcon,
  [OrderStatuses.Cancelled]: OrderStatusPrescriptionExpiredIcon,
  [OrderStatuses.Complete]: OrderStatusMedicationReadyIcon,
  [OrderStatuses.Archived]: OrderStatusMedicationReadyIcon,

  // default
  undefined: OrderStatusPaymentNeededIcon,
};

// Text for OrderHeader based on order status
const OrderHeaderToTextMapper = {
  [OrderStatuses.OutOfStock]: {
    title: "Your order is with the manufacturer",
    text:
      "Your order is currently with the manufacturer and waiting to be Shipped.",
  },

  [OrderStatuses.OutOfStockAndPaper]: {
    title: "Your order is with the manufacturer",
    text:
      "Your order is currently with the manufacturer and waiting to be Shipped.",
  },

  [OrderStatuses.NotPaidAndNoPaper]: {
    title: "Your medication is now ready for payment ",
    text:
      "We are waiting for your doctor to send us the paper copy of your prescription. Once we have received this, we will order your medication and prepare it to be dispensed. You will receive an email notification when it is ready to be shipped and you can make payment.",
  },
  [OrderStatuses.NotPaidAndPaper]: {
    title: "Your medication is now ready for payment ",
    text:
      "We just need your payment. Please see your current prescription status for more details.",
  },
  [OrderStatuses.PaidAndNoPaper]: {
    title: "Awaiting paper prescription",
    text:
      "You have paid for this medication, but we are still waiting for the physical paper prescription to arrive at the pharmacy. Once it has arrived at the pharmacy your order status will be updated. ",
  },

  [OrderStatuses.PaidAndPaper]: {
    title: "We're preparing your medication",
    text:
      "We're preparing your order for shipment. You'll receive an email as soon as it is dispatched",
  },

  [OrderStatuses.Shipped]: {
    title: "Your order has been dispatched",
    text:
      <div>
        <div>Your medication has been dispatched and is on the way.</div>
        <div>Please use your tracking information to view your delivery status.</div>
      </div>,
  },

  [OrderStatuses.PaperAndPaymentMissed]: {
    title: "Payment deadline missed",
    text:
      "Unfortunately, we didn’t receive payment so we're unable to ship your medication. To re-reserve your item, please press resubmit and we will notify you when it is ready for payment.",
  },
  [OrderStatuses.NoPaperAndPaymentMissed]: {
    title: "Payment deadline missed",
    text:
      "Unfortunately, we didn’t receive payment so we're unable to ship your medication. To re-reserve your item, please press resubmit and we will notify you when it is ready for payment.",
  },

  [OrderStatuses.Void]: {
    title: "This prescription has expired",
    text:
      "Sorry, your last prescription has expired and can no longer be used. You'll need to book another appointment and ask your doctor to reissue your prescription before we can order your medication.",
  },

  [OrderStatuses.Cancelled]: {
    title: "This order has been cancelled",
    text:
      "",
  },

  [OrderStatuses.Complete]: {
    title: "This order has been completed",
    text:
      "",
  },

  // default
  undefined: {
    title: "This prescription has expired",
    text:
      "Sorry, your last prescription has expired and can no longer be used. You'll need to book another appointment and ask your doctor to reissue your prescription before we can order your medication.",
  },
};

Object.freeze(OrderHeaderToTextMapper);


const OrderHeaderToTextMapperForDoctor = {
  [OrderStatuses.OutOfStock]: {
    title: "The order is with the manufacturer",
    text:
      "The order is currently with the manufacturer and waiting to be Shipped.",
  },

  [OrderStatuses.OutOfStockAndPaper]: {
    title: "The order is with the manufacturer",
    text:
      "The order is currently with the manufacturer and waiting to be Shipped.",
  },

  [OrderStatuses.NotPaidAndNoPaper]: {
    title: "We need the paper prescription",
    text: <div className='p-5'>We are still waiting for the physical paper prescription. Please send us this as soon as you can to avoid any delays to <span className='bold'>28A Sherwood Street, NG20 0JW Warsop, Nottinghamshire, England</span> </div>,
  },
  [OrderStatuses.NotPaidAndPaper]: {
    title: "Your patient's medication is ready",
    text:
      "Awaiting payment. Please see current prescription status for more details.",
  },
  [OrderStatuses.PaidAndNoPaper]: {
    title: "We need the paper prescription",
    text:
      <div className="p-5">We are still waiting for the physical paper prescription. Please send us this as soon as you can to avoid any delays to <span className='bold'>28A Sherwood Street, NG20 0JW Warsop, Nottinghamshire, England</span> </div>,
  },

  [OrderStatuses.PaidAndPaper]: {
    title: "We're preparing the medication",
    text:
      "We're preparing your patient's order for shipment. Your patient will receive an email as soon as it is dispatched",
  },

  [OrderStatuses.Shipped]: {
    title: "This order has been dispatched",
    text:
      "This medication has been dispatched and should be with your patient within 24 hrs.",
  },

  [OrderStatuses.PaperAndPaymentMissed]: {
    title: "Payment deadline missed",
    text:
      "Unfortunately, we didn’t receive a payment so we’re unable to guarantee the prescription. Please contact the Patient and we’ll aim to get your medication secured and ready for payment.  ",
  },
  [OrderStatuses.NoPaperAndPaymentMissed]: {
    title: "Payment deadline missed",
    text:
      "Unfortunately, we didn’t receive a payment so we’re unable to guarantee the prescription. Please contact the Patient and we’ll aim to get your medication secured and ready for payment.  ",
  },

  [OrderStatuses.Void]: {
    title: "This prescription has expired",
    text:
      "Sorry, the prescription has expired and can no longer be used. The Patient will need to book another appointment and ask the doctor to reissue a prescription before we can order the medication.",
  },

  [OrderStatuses.CompletedAndVoid]: {
    title: "This prescription has expired",
    text:
      "Sorry, the prescription has expired and can no longer be used. The Patient will need to book another appointment and ask the doctor to reissue a prescription before we can order the medication.",
  },

  [OrderStatuses.Cancelled]: {
    title: "This order has been cancelled",
    text:
      "",
  },

  [OrderStatuses.Complete]: {
    title: "This order has been completed",
    text:
      "",
  },

  [OrderStatuses.Archived]: {
    title: "This order has been archived",
    text:
      "",
  },

  // default
  undefined: {
    title: "This prescription has expired",
    text:
      "Sorry, the prescription has expired and can no longer be used. The Patient will need to book another appointment and ask the doctor to reissue a prescription before we can order the medication.",
  },
};

Object.freeze(OrderHeaderToTextMapper);